






























import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseDeliveryOrder, ResponseListDeliveryOrder } from "@interface/delivery-order.interface";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { deliveryOrderService } from "@service/delivery-order.service";
export default Vue.extend({
  name: "CSelectDeliveryOrderNumber",
  mixins: [
    MNotificationVue
  ],
  props: {
    value: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    propSearchBy: {
      type: String,
      default: undefined,
    }
  },
  data() {
    this.search = debounceProcess(this.search, 300);
    return {
      loading: false,
      dtOpt: [] as IOption<ResponseDeliveryOrder>[],
      queryParams: {
        page: 0,
        limit: 10,
        search: ""
      },
      dtDeliveryOrder: {} as ResponseListDeliveryOrder,
    };
  },
  created() {
    if (this.propSearchBy) this.queryParams.search = this.propSearchBy;
    this.getList(this.queryParams);
  },
  methods: {
    getListDeliveryOrder(params: RequestQueryParamsModel): Promise<ResponseListDeliveryOrder> {
      return deliveryOrderService.getList(params);
    },
    onSelect(e: string, meta: ResponseDeliveryOrder): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
    search(search = ""): void {
      let searchBy : string[] = [];
      if (search) {
        searchBy.push(`deliveryOrderNumber~*${search}*`);
      }
      if (this.propSearchBy) searchBy.push(this.propSearchBy);
      this.queryParams.search = searchBy.join("_AND_");
      this.queryParams.page = 0;
      this.dtOpt = [];
      this.getList(this.queryParams);
    },
    popupScroll(e): void {
      if ((this.dtDeliveryOrder.totalPages - 1) === this.dtDeliveryOrder.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListDeliveryOrder(params);
        const { dtOpt } = this;
        const opts = res.data.map(x => ({ key: x.deliveryOrderNumber, value: x.id, meta: x }));
        this.dtOpt = [...dtOpt, ...opts];
        this.dtDeliveryOrder = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
  }
});
